<template>
	<div>
		<Background class="background" :target="slidingPagesSlug" color="rgba(0,0,0,0.2)">
			<div>
				<PatternShapes class="pattern-shape" ref="patternShapes" :backgroundTarget="slidingPagesSlug" />
				<div :class="`image background-${slidingPagesSlug}`" v-if="slides && slides[currentSlideId]">
					<ImageShape
						category="reportages"
						:srcImage="slides[currentSlideId].featuredImage.node.mediaItemUrl"
						:blend="true"
					/>
				</div>
				<swiper class="swipe" ref="swiper" :options="swiperOption" @slideChange="onSlideChange">
					<swiper-slide
						v-for="(slide, i) in slides"
						:key="i"
						class="slide"
						:style="{ width: slide.width + 'px' }"
					>
						<h2 class="title_swiper" @click="swiper.slideTo(i)">
							{{ slide.title }}
						</h2>
					</swiper-slide>
				</swiper>
			</div>
		</Background>
		<Loader :background="slidingPagesSlug" template="tiny" :status="loading" />
		<div class="content">
			<v-slide-y-reverse-transition>
				<RenderBlocks
					v-if="loadedContent[currentSlideId]"
					:blocks="loadedContent[currentSlideId].blocksJSON"
					:key="currentSlideId"
				/>
			</v-slide-y-reverse-transition>
			<div class="loading" v-if="$apollo.loading"></div>
		</div>
	</div>
</template>

<script>
import Background from "@/components/ui/Background";
import ImageShape from "@/components/ui/ImageShape";
import Loader from "@/components/ui/Loader";
import PatternShapes from "@/components/ui/PatternShapes";
import RenderBlocks from "@/components/blocks/RenderBlocks";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { GET_SLIDING_PAGES, GET_PAGE } from "@/queries/Pages.js";

export default {
	metaInfo() {
		if (this.seo) {
			return {
				title: this.seo.title,
				meta: [
					{
						name: "description",
						content: this.seo.metaDesc,
					},
					{
						name: "keywords",
						content: this.seo.metaKeywords ? this.seo.metaKeywords : this.defaultMetaKeywords,
					},
					{
						name: "author",
						content: this.seo.opengraphSiteName,
					},
					{
						property: "og:title",
						content: this.seo.title,
					},
					{
						property: "og:type",
						content: this.seo.opengraphType,
					},
					{
						property: "og:url",
						content: this.seo.opengraphUrl,
					},
					{
						property: "og:description",
						content: this.seo.metaDesc,
					},
					{
						property: "og:image",
						content: this.seo.opengraphImage ? this.seo.opengraphImage.sourceUrl : this.defaultUrlImage,
					},
					{ name: "robots", content: "index,follow" },
				],
			};
		}
	},
	props: {},
	components: {
		RenderBlocks,
		Background,
		ImageShape,
		PatternShapes,
		Swiper,
		SwiperSlide,
		Loader,
	},
	data() {
		return {
			activetab: 0,
			currentSlideId: 0,
			data: null,
			firstTime: true,
			loading: true,
			loadedContent: [],
			slides: [],
			seo: null,
			swiperOption: {
				slidesPerView: "auto",
				spaceBetween: 0,
				centeredSlides: true,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
			},
		};
	},
	computed: {
		swiper() {
			return this.$refs.swiper.$swiper;
		},
	},
	async mounted() {
		this.loadStart();
		this.loading = true;
		this.pageSlug = this.$route.params.pageSlug || "bambini-e-famiglie";
		await this.loadSlidesName();
		this.loadDone();
	},
	methods: {
		async loadPageData(slug) {
			let query = {
				query: GET_PAGE(slug),
			};
			let data = await this.$apollo.query(query);
			this.loadedContent[this.currentSlideId] = data.data.pages.nodes[0];
			setTimeout(
				function() {
					this.loading = false;
				}.bind(this),
				500
			);
		},
		async loadSlidesName() {
			let query = {
				query: GET_SLIDING_PAGES("sliding-pages"),
			};
			this.data = await this.$apollo.query(query);

			let slides = this.data.data.page.children.nodes;
			slides.map((slide) => {
				slide.qzr_target = slide.terms.nodes.length > 1 ? "all" : slide.terms.nodes[0].slug;
				slide.width =
					window.innerWidth > 768
						? this.getTextWidth("110px", slide.title)
						: window.innerWidth > 550
						? this.getTextWidth("64px", slide.title)
						: this.getTextWidth("32px", slide.title);
			});

			let index = slides
				.map(function(e) {
					return e.slug;
				})
				.indexOf(this.pageSlug);

			if (index === -1) {
				this.get404();
				return;
			}

			this.slides = slides;
			this.seo = slides[index].seo;
			await this.loadPageData(slides[index].slug);
			this.updateProperty({
				property: "slidingPagesSlug",
				value: slides[index].qzr_target,
			});
			this.swiper.slideTo(index);
		},
		async onSlideChange() {
			this.loading = true;
			this.currentSlideId = this.swiper.activeIndex;
			let activeElement = this.slides[this.currentSlideId];
			this.updateProperty({
				property: "slidingPagesSlug",
				value: activeElement.qzr_target,
			});
			this.$refs.patternShapes.changePattern();
			this.changeUrlWithoutNavigation(activeElement.slug);
			await this.loadPageData(activeElement.slug);

			this.seo = this.slides[this.currentSlideId].seo;
			setTimeout(
				function() {
					this.loading = false;
				}.bind(this),
				500
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.background {
	height: 60vh;
	width: 100vw;
	position: relative;
}

.pattern-shape {
	height: 60vh;
}

.swipe {
	height: 60vh;
	width: 100vw;
}

.swiper-slide-active {
	opacity: 1 !important;
}

.slide {
	text-align: center;
	line-height: 60vh;
	opacity: 0.4;
	transition: 0.6s opacity;
}

.title_swiper {
	color: #fff;
	display: inline-block;
	font-size: $font-size-100;
}

.image {
	border-radius: 100%;
	left: 50%;
	object-fit: cover;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 400px;
	width: 400px;
}

.title_swiper {
	cursor: pointer;
}

.content {
	min-height: 100vh;
}
@media only screen and (max-width: 1024px) {
	.title_swiper {
		font-size: 4rem;
	}
}
@media only screen and (max-width: 768px) {
	.title_swiper {
		font-size: 3rem;
	}
	.image {
		width: 280px;
		height: 280px;
	}
}
@media only screen and (max-width: 550px) {
	.title_swiper {
		font-size: 2rem;
	}
}
</style>
